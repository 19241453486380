import React, { useEffect } from "react";

import "./GuidedPhonicsLesson.scss";

const GuidedPhonicsLesson = ({
  slide,
  name,
  fullscreen,
  setAnimationMaxCount,
}) => {
  const { content } = slide;
  const { grade, lesson, background } = content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`guided-phonics-lesson-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`guided-phonics-lesson-${name}`}
    >
      <div
        className="guided-phonics-background"
        style={{
          backgroundImage: `url(https://photos.earlyreader.ai/nook-${background}.webp)`,
        }}
      />

      <div className="guided-phonics-overlay">
        <h1
          className={`guided-phonics-title ${
            fullscreen ? "gpt-fullscreen" : ""
          }`}
        >
          Guided Phonics Lesson
        </h1>
        <div className="guided-phonics-grade-label">
          Grade {grade}
          <br />
          Lesson {lesson}
        </div>
      </div>
    </div>
  );
};

export default GuidedPhonicsLesson;
