import React, { useEffect, useState } from "react";

import { getSoundPhoto } from "../../../Utils/Phones/soundPositions";
import { selectCell } from "../ConsonantSounds.logic";

import "./ConsonantSoundCell.scss";

const ConsonantSoundCell = ({ cell, className, fullscreen, highlights }) => {
  const [voiced, setVoiced] = useState([]);
  const [graphemes, setGraphemes] = useState([]);
  const [faceImage, setFaceImage] = useState(null);
  const [itemImage, setItemImage] = useState([]);
  const [styleOverride, setStyleOverride] = useState({});

  useEffect(() => {
    selectCell({
      cell,
      setVoiced,
      setGraphemes,
      setFaceImage,
      setItemImage,
      setStyleOverride,
    });
  }, []);

  return (
    <div className={className}>
      <img
        className="face-card"
        src={getSoundPhoto(faceImage)}
        alt={faceImage}
      />
      <div className="graphemes-inner-cell">
        {voiced.map((innerCell, index) => {
          if (innerCell === "voiced")
            return (
              <img
                key={`face-${innerCell}-${index}`}
                className={`${voiced.length > 1 ? "one-" : ""}voiced-sound`}
                src={getSoundPhoto("speakerIcon")}
                alt={"speakerIcon"}
              />
            );
        })}
        {graphemes.map((innerCell, index) => {
          return (
            <h1
              key={`grapheme-${innerCell}-${index}`}
              className={`${fullscreen ? "fullscreen" : ""} ${
                highlights.includes(innerCell.replaceAll("/", "").trim())
                  ? "highlight"
                  : ""
              }`}
            >
              {innerCell}
            </h1>
          );
        })}
        {itemImage.map((innerCell, index) => {
          return (
            <img
              key={`image-${innerCell}-${index}`}
              className="item-photo"
              src={getSoundPhoto(itemImage[index])}
              alt={itemImage[index]}
              style={styleOverride[index]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConsonantSoundCell;
