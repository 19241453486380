import React, { useEffect } from "react";
import "./GuidedPhonicsTarget.scss";

// Helper to parse custom markup to spans
const parseFormattedText = (text) => {
  return text
    .replace(/<gray>(.*?)<\/gray>/g, '<span class="gray">$1</span>')
    .replace(/<bold>(.*?)<\/bold>/g, '<span class="bold">$1</span>')
    .replace(/<half>(.*?)<\/half>/g, '<span class="half">$1</span>');
};

const GuidedPhonicsTarget = ({
  slide,
  name,
  fullscreen,
  setAnimationMaxCount,
}) => {
  const { liList, scList, pictures } = slide.content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`guided-phonics-target-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`guided-phonics-target-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/target.webp)`,
      }}
    >
      <div className="left-panel">
        <h2 className={`header ${fullscreen ? "h-fullscreen" : ""}`}>
          Learning Intention
        </h2>
        <div className={`li-body ${fullscreen ? "t-fullscreen" : ""}`}>
          {liList.map((item, idx) => (
            <p
              key={`li-${idx}`}
              dangerouslySetInnerHTML={{ __html: parseFormattedText(item) }}
            />
          ))}
        </div>
      </div>

      <div className="right-panel">
        <h2 className={`header ${fullscreen ? "h-fullscreen" : ""}`}>
          Success Criteria
        </h2>

        <div className="sc-pictures">
          {pictures.map(({ picture, graphemes }, idx) => (
            <div key={`pic-${idx}`} className="sc-card">
              <img
                src={`https://photos.earlyreader.ai/${picture}.webp`}
                alt={picture}
                className="sc-image"
              />
              <div className="sc-graphemes">
                {graphemes.map((g, i) => (
                  <span key={`g-${i}`} className="grapheme-box">
                    {g}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={`sc-body ${fullscreen ? "t-fullscreen" : ""}`}>
          {scList.map((item, idx) => (
            <p
              key={`sc-${idx}`}
              dangerouslySetInnerHTML={{ __html: parseFormattedText(item) }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuidedPhonicsTarget;
