// BlendingRoutine.js
import React, { useEffect, useRef, useState } from "react";
import {
  fetchPhonemeTiles,
  getBlendingPhase,
  getMaxAnimationSteps,
  mapSyllableStringsToTileIndices,
} from "./BlendingRoutine.logic";

import "./BlendingRoutine.scss";

const BlendingRoutine = ({
  slide,
  animation,
  fullscreen,
  setAnimationMaxCount,
  setAnimation,
}) => {
  const {
    word,
    background = "material-1",
    level,
    photoVar = 0,
  } = slide.content;

  const [phonemeData, setPhonemeData] = useState(null);
  const [arrowStyle, setArrowStyle] = useState(null);
  const [imageReady, setImageReady] = useState(false);
  const tileRefs = useRef([]);

  useEffect(() => {
    if (!phonemeData) return;

    (async () => {
      const maxSteps = await getMaxAnimationSteps(phonemeData, level);
      setAnimationMaxCount(maxSteps);
    })();
  }, [phonemeData, level]);

  useEffect(() => {
    fetchPhonemeTiles(word).then((tiles) => {
      const rawGrouping = slide.content.grouping;
      const convertedGrouping = mapSyllableStringsToTileIndices(
        rawGrouping,
        tiles.graphemeTiles
      );

      if (convertedGrouping && slide.content.syllableType) {
        setPhonemeData({
          ...tiles,
          grouping: convertedGrouping,
          syllableType: slide.content.syllableType,
        });
      } else {
        setPhonemeData({
          ...tiles,
        });
      }
    });
  }, [word]);

  useEffect(() => {
    if (!phonemeData) return;

    const phase = getBlendingPhase(phonemeData, animation, level);

    if (!phase.arrowSpan || phase.showImage) {
      setArrowStyle(null);
      return;
    }

    let [startIdx, endIdx] = phase.arrowSpan;

    const startWhole = Math.floor(startIdx);
    const endWhole = Math.floor(endIdx);

    const startEl = tileRefs.current[startWhole];
    const endEl = tileRefs.current[endWhole];

    if (!startEl || !endEl) {
      setArrowStyle(null);
      return;
    }

    const startOffset =
      startEl.offsetLeft + (startIdx % 1 === 0.5 ? startEl.offsetWidth / 2 : 0);

    const endOffset =
      endEl.offsetLeft +
      (endIdx % 1 === 0.5 ? endEl.offsetWidth / 2 : endEl.offsetWidth);

    setArrowStyle({
      left: startOffset,
      width: endOffset - startOffset,
    });
  }, [phonemeData, animation, level]);

  useEffect(() => {
    const img = new Image();
    img.src = `https://photos.earlyreader.ai/${word}${
      photoVar ? `-${photoVar}` : ""
    }.webp`;
    img
      .decode()
      .then(() => setImageReady(true))
      .catch(() => setImageReady(true));
  }, [word]);

  useEffect(() => {
    if (!phonemeData) return;

    const phase = getBlendingPhase(phonemeData, animation, level);
    const { shouldAutoAdvance } = phase;

    const timeoutMs = 250;

    if (shouldAutoAdvance) {
      const timeout = setTimeout(() => {
        setAnimation((prev) => prev + 1);
      }, timeoutMs);

      return () => clearTimeout(timeout);
    }
  }, [animation, phonemeData]);

  if (!phonemeData) {
    return (
      <div
        className={`blending-routine-container ${
          fullscreen ? "slide-fullscreen" : "not-fullscreen"
        }`}
        style={{
          backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
        }}
      >
        <div className="grapheme-row placeholder-row" />
      </div>
    );
  }
  const phase = getBlendingPhase(phonemeData, animation, level);

  return (
    <div
      className={`blending-routine-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
      }}
    >
      <div className={`grapheme-row ${phase.showImage ? "moved-up" : ""}`}>
        {phonemeData.graphemeTiles.map((tile, index) => {
          const type = phase.tileTypes?.[index];
          const isActive = phase.activeTiles.includes(index);

          let animationClass = "";
          if (isActive) {
            if (
              type === "schwa" &&
              animation !== phonemeData?.ipaPhonemes?.length + 2
            )
              animationClass = "pulse";
            else animationClass = "teeter";
          }

          return (
            <div
              key={`tile-${index}`}
              ref={(el) => {
                tileRefs.current[index] = el;
                if (el) {
                  el.classList.remove("teeter", "spin", "pulse");
                  void el.offsetWidth;
                  if (animationClass) el.classList.add(animationClass);
                }
              }}
              className={`grapheme-tile ${type || ""} ${
                phase.growTiles || phase.showImage ? "grow" : ""
              } ${tile.includes("(") ? "gray" : ""}`.trim()}
            >
              {tile.replace(/[()]/g, "")}
            </div>
          );
        })}
        {arrowStyle && <div className="arrow" style={arrowStyle} />}
      </div>

      <div className={`final-reveal ${phase.showImage ? "show" : "none"}`}>
        <img
          className="photo-image"
          src={`https://photos.earlyreader.ai/${word}${
            photoVar ? `-${photoVar}` : ""
          }.webp`}
          alt={word}
        />
      </div>
    </div>
  );
};

export default BlendingRoutine;
