import axios from "axios";

const cmuToIpaMap = {
  // Consonants
  P: "p", // "pen"
  B: "b", // "bat"
  M: "m", // "man"
  WH: "ʍ", // "whale"
  W: "w", // "wet"
  F: "f", // "fish"
  V: "v", // "van"
  TH: "θ", // "thin" (voiceless)
  DH: "ð", // "this" (voiced)
  T: "t", // "top"
  D: "d", // "dog"
  N: "n", // "no"
  S: "s", // "see"
  Z: "z", // "zoo"
  L: "l", // "lip"
  SH: "ʃ", // "she"
  ZH: "ʒ", // "measure"
  CH: "t͡ʃ", // "chip"
  JH: "d͡ʒ", // "jam"
  Y: "j", // "yes"
  R: "ɹ", // "run"
  K: "k", // "cat"
  G: "g", // "go"
  NG: "ŋ", // "ring"
  HH: "h", // "hat"

  // Vowels with stress markers
  IY: "i", // Long e, "happy"
  IH: "ɪ", // Short i, "ladies"
  EY: "eɪ", // Long a, "great"
  EH: "ɛ", // Short e, "friend"
  AE: "æ", // Short a, "cat"
  AY: "aɪ", // long i, "hi"
  AA: "ɔ", // Open back unrounded vowel, "father"
  AH: "ʌ", // Short u, "hut"
  AO: "ɑ", // Short o, "thought"
  OW: "oʊ", // Long o, "go"
  UH: "ʊ", // Oo (book), "put"
  UW: "u", // Long u (spoon), "food"
  YU_: "ju", // Long u (cube), "few"
  AH0: "ə", // Schwa, "sofa"

  // Diphthongs
  OY: "ɔɪ", // /oi/, "boy"
  AW: "aʊ", // /aw/, "now"

  // R-Controlled
  ER: "ɹ̩", // R-colored schwa, "butter"
  AR: "ɑɹ", // /ar/, "car"
  OR: "ɔɹ", // /or/, "for"
};

const ipaToGraphemeMap = {
  // Lips Together
  p: ["p", "pp"], // "pen", "apple"
  b: ["b", "bb"], // "bat", "bubble"
  m: ["m", "mm", "mb", "mn"], // "man", "hammer", "column", "thumb"
  ʍ: ["wh"], // "whale"
  w: ["w", "wh"], // "wet"

  // Teeth on Lip
  f: ["f", "ff", "ph", "gh"], // "fish", "coffee", "phone", "laugh"
  v: ["v", "f", "ph"], // "van", "of", "Stephen"

  // Tongue Between Teeth
  θ: ["th"], // "thin"
  ð: ["th"], // "this"

  // Tongue on Ridge Behind Teeth
  t: ["t", "tt", "ed"], // "top", "letter", "looked"
  d: ["d", "dd", "ed"], // "dog", "add", "played"
  n: ["n", "nn", "kn", "gn", "pn"], // "no", "dinner", "knee", "gnat", "pneumonia"
  s: ["s", "ss", "c", "sc", "ps"], // "see", "class", "cent", "science", "psychology"
  z: ["z", "zz", "s", "ss", "x"], // "zoo", "buzz", "is", "dessert", "xylophone"
  l: ["l", "ll"], // "lip", "bell"

  // Tongue Pulled Back on Roof of Mouth
  ʃ: ["sh", "ti", "ci", "ch", "ssi", "c", "sc", "sci"], // "she", "nation", "precious", "machine", "passion", "ocean", "conscious"
  ʒ: ["s", "g", "j", "dg"], // "measure", "genre", "mirage", "journey", "bridge"
  t͡ʃ: ["ch", "tch", "tu", "ti"], // "chip", "watch", "nature", "question"
  d͡ʒ: ["j", "g", "dge", "di", "d"], // "jam", "giraffe", "edge", "soldier"
  j: ["y", "i"], // "yes", "onion"
  ɹ: ["r", "rr", "wr", "rh"], // "run", "arrow", "write", "rhyme"

  // Back of Throat
  k: ["k", "c", "ck", "ch", "cc", "que", "q"], // "cat", "kick", "character", "accord", "boutique"
  g: ["g", "gg", "gh", "gue"], // "go", "egg", "ghost", "league"
  ŋ: ["ng", "n"], // "ring", "sink"

  // Glottis
  h: ["h"], // "hat"

  // Vowels
  i: ["ee", "ea", "e", "ie", "ei", "ey", "i", "y"], // "see", "sea", "be", "field", "ceiling", "key", "machine", "happy"
  ɪ: ["i", "y", "e", "ui", "a"], // "sit", "gym", "pretty", "build"
  eɪ: ["a", "ai", "ay", "ei", "ey", "ea"], // "face", "wait", "day", "vein", "they", "great"
  ɛ: ["e", "ea", "ai", "a"], // "bed", "head", "said", "chair, "hare"
  æ: ["a", "ai", "au"], // "cat", "plaid", laugh
  aɪ: ["i", "y", "igh", "ie", "uy", "ai", "eye"], // "time", "my", "light", "pie", "guy", "aisle", "eye"
  ɔ: ["aw", "au", "augh", "ou", "a", "o", "e"], // "thought", "caught", "father", palm, "astronaut"
  ʌ: ["u", "o", "ou"], // "cup", "son", "touch"
  ɑ: ["o", "augh", "ough", "a", "ah", "au"], // "crawl", "father", "baugh", "auger",
  oʊ: ["o", "oa", "ow", "oe", "ough", "ew"], // "go", "boat", "snow", "toe", "though", "sew"
  ʊ: ["oo", "u", "ou", "o"], // "put", "foot", "could", "wolf"
  u: ["oo", "ue", "ew", "o", "ui", "ou", "ough", "eu", "u"], // "food", "blue", "grew", "do", "fruit", "soup", "through"
  ju: ["u", "ue", "ew", "you"], // "cube", "hue", "few", "barbeque"
  ə: ["ou", "a", "e", "i", "o", "u", "y", "ea", "ai"], // "sofa", "taken", "pencil", "syrup", "banana", "precious", "ocean"

  // Diphthongs
  ɔɪ: ["oi", "oy", "eu"], // "boy", "coin", neu
  aʊ: ["ou", "ow", "ough"], // "now", "house"

  // R-Controlled
  ɹ̩: ["er", "ir", "ur", "ear", "or", "err", "our", "r"], // "her", "bird", "turn", "learn", "work", "merry", "journey"
  ɑɹ: ["ar", "ear", "a", "ear"], // "car", "heart", "are"
  ɔɹ: ["or", "ore", "oar", "our", "aur"], // "for", "store", "roar", "four", "aurora"
};

// Function to convert CMU phonemes to IPA with handling for stress markers
export function convertCmuToIpa(cmuPhonemes) {
  const mergedPhonemes = [];
  let skipNext = false;

  for (let i = 0; i < cmuPhonemes.length; i++) {
    if (skipNext) {
      skipNext = false;
      continue;
    }

    let current = cmuPhonemes[i];
    const next = cmuPhonemes[i + 1]?.replace(/[0-2]$/, ""); // Remove stress marker for the next phoneme

    // Preserve AH0 for schwa, remove stress markers for others
    if (!/^AH0$/.test(current)) {
      current = current.replace(/[0-2]$/, ""); // Remove stress marker
    }

    // Handle the specific cases
    if (current === "HH" && next === "W") {
      mergedPhonemes.push("ʍ"); // "wh" in IPA
      skipNext = true;
    } else if (current === "AA" && next === "R") {
      mergedPhonemes.push("ɑɹ"); // "ar" in IPA
      skipNext = true;
    } else if (current === "AO" && next === "R") {
      mergedPhonemes.push("ɔɹ"); // "or" in IPA
      skipNext = true;
    } else if (current === "Y" && next === "UW") {
      mergedPhonemes.push("ju"); // "yu" in IPA
      skipNext = true;
    } else if (current === "IH" && next === "R") {
      mergedPhonemes.push("i"); // actually long e
    } else {
      // Default case: map individual phoneme
      mergedPhonemes.push(cmuToIpaMap[current] || current);
    }
  }

  // To be used later for comparison.
  // console.log(cmuPhonemes, mergedPhonemes);

  return mergedPhonemes;
}

// Maps IPA phonemes to grapheme tiles with capitalization handling
export function mapIpaToGraphemes(ipaPhonemes, word) {
  // Normalize input but keep track of original case
  const lowerWord = word.toLowerCase();

  // Step 1: Filter grapheme options from ipaToGraphemeMap
  const graphemeOptions = ipaPhonemes.map(
    (phoneme) => ipaToGraphemeMap[phoneme] || []
  );

  // Step 2: Handle special cases for multi-phoneme combinations
  const twoPhonemeCombinations = {
    "k,w": ["q", "u"], // Separate tiles for "queen" and "quack"
    "k,s": ["x"], // Single tile for "box"
  };

  function matchTwoPhonemes(ipaIndex) {
    if (ipaIndex + 1 < ipaPhonemes.length) {
      const key = `${ipaPhonemes[ipaIndex]},${ipaPhonemes[ipaIndex + 1]}`;
      return twoPhonemeCombinations[key] || [];
    }
    return [];
  }

  // Step 3: Generate possible combinations recursively
  const results = [];
  function backtrack(ipaIndex, wordIndex, path, silentCount) {
    // If we've processed all IPA phonemes and matched the word
    if (ipaIndex >= ipaPhonemes.length && wordIndex >= lowerWord.length) {
      results.push({ graphemes: [...path], silentCount });
      return;
    }

    // Skip over schwas that don't align with the word
    if (ipaIndex < ipaPhonemes.length && ipaPhonemes[ipaIndex] === "ə") {
      backtrack(ipaIndex + 1, wordIndex, path, silentCount);
    }

    // If there are unmatched word letters, insert them as silent
    if (wordIndex < lowerWord.length) {
      backtrack(
        ipaIndex,
        wordIndex + 1,
        [...path, `(${word[wordIndex]})`],
        silentCount + 1
      );
    }

    // Handle two-phoneme graphemes
    const twoPhonemeMatches = matchTwoPhonemes(ipaIndex);
    for (const grapheme of twoPhonemeMatches) {
      const combinedGrapheme = twoPhonemeMatches.join("");
      if (lowerWord.slice(wordIndex).startsWith(combinedGrapheme)) {
        backtrack(
          ipaIndex + 2,
          wordIndex + combinedGrapheme.length,
          [...path, ...twoPhonemeMatches],
          silentCount
        );
      }
    }

    // Match single phoneme graphemes
    if (ipaIndex < ipaPhonemes.length) {
      const options = graphemeOptions[ipaIndex];
      for (const grapheme of options) {
        if (lowerWord.slice(wordIndex).startsWith(grapheme)) {
          backtrack(
            ipaIndex + 1,
            wordIndex + grapheme.length,
            [...path, grapheme],
            silentCount
          );
        }
      }
    }
  }

  backtrack(0, 0, [], 0);

  // Step 4: Select the best result with the least silent letters
  results.sort((a, b) => {
    if (a.silentCount !== b.silentCount) return a.silentCount - b.silentCount;
    // Favor paths with longer graphemes
    const aLength = a.graphemes.reduce((sum, g) => sum + g.length, 0);
    const bLength = b.graphemes.reduce((sum, g) => sum + g.length, 0);
    return bLength - aLength;
  });

  // Step 5: Preserve capitalization in the final graphemes
  const bestResult = results[0]?.graphemes || [];
  const capitalizedResult = [];
  let originalIndex = 0; // Tracks the index in the original word

  bestResult.forEach((grapheme) => {
    const cleanGrapheme = grapheme.replace(/[()]/g, ""); // Remove parentheses temporarily
    let newGrapheme = ""; // Build the new grapheme with correct capitalization

    // Iterate through each letter in the grapheme
    for (let i = 0; i < cleanGrapheme.length; i++) {
      const isUppercase =
        originalIndex < word.length &&
        word[originalIndex] === word[originalIndex].toUpperCase();
      const letter = cleanGrapheme[i];
      newGrapheme += isUppercase ? letter.toUpperCase() : letter.toLowerCase();
      originalIndex++; // Move to the next letter in the original word
    }

    // Re-add parentheses if they were part of the original grapheme
    capitalizedResult.push(
      grapheme.includes("(") ? `(${newGrapheme})` : newGrapheme
    );
  });

  return capitalizedResult;
}

// Fetch phonemes for a word from an API
export async function getPhonemes(word, pronunciationVariation = 0) {
  try {
    const response = await axios.get(`/api/phonemes?word=${word}`);
    if (response.data.message) return response.data.message;
    return response.data[pronunciationVariation];
  } catch (error) {
    return null;
  }
}

// New function to get syllable information using CMU
export async function getSyllableInfoFromCmu(word) {
  try {
    const response = await axios.get(`/api/syllables?word=${word}`);
    const syllables = response.data.syllables || [];

    return syllables.map((syllable) => {
      // Determine syllable type based on phoneme patterns
      if (syllable.includes("ː") || syllable.endsWith("e")) return "v_e";
      if (syllable.includes("ə")) return "schwa";
      if (/[aæɛiɔʊ]/.test(syllable)) return "breve"; // Short vowels
      if (/[iːuːeɪoʊ]/.test(syllable)) return "macron"; // Long vowels
      return "";
    });
  } catch (error) {
    return [];
  }
}
