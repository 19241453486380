export const lessonDataTemp = [
  {
    slideType: "guided-phonics-lesson",
    content: {
      grade: "2",
      lesson: "1",
      background: "2",
    },
  },
  {
    slideType: "guided-phonics-listen",
    notes: [
      {
        header: "Blending",
        list: ["sear", "gear", "work", "world", "polar"],
      },
      {
        header: "Segmenting",
        list: ["world", "worst", "solar", "dollar", "actor"],
      },
    ],
  },
  {
    slideType: "guided-phonics-review",
    content: {
      reviewName: "(visual)",
      background: "review-1",
    },
  },
  { randomize: 20 },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["or"],
        select: 0,
        phonemes: ["or", "er"],
        background: ["vowel-wall"],
        photos: ["short", "worm"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ar"],
        select: 0,
        phonemes: ["ar", "er"],
        background: ["vowel-wall"],
        photos: ["barn", "dollar"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["eer"],
        select: 0,
        phonemes: ["ēr"],
        background: ["vowel-wall", "consonant-wall"],
        photos: ["deer"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ear"],
        select: 0,
        phonemes: ["ēr", "ĕr"],
        background: ["vowel-wall", "consonant-wall"],
        photos: ["ear", "bear"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["er"],
        select: 0,
        phonemes: ["er"],
        background: ["vowel-wall"],
        photos: ["ladder"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ir"],
        select: 0,
        phonemes: ["er"],
        background: ["vowel-wall"],
        photos: ["bird"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ur"],
        select: 0,
        phonemes: ["er"],
        background: ["vowel-wall"],
        photos: ["fur"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["our"],
        select: 0,
        phonemes: ["or"],
        background: ["vowel-wall"],
        photos: ["four"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ore"],
        select: 0,
        phonemes: ["or"],
        background: ["vowel-wall"],
        photos: ["tore"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["are"],
        select: 0,
        phonemes: ["ĕr"],
        background: ["vowel-wall"],
        photos: ["flare"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["air"],
        select: 0,
        phonemes: ["ĕr"],
        background: ["vowel-wall"],
        photos: ["hair"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["igh"],
        select: 0,
        phonemes: ["ī"],
        background: ["vowel-wall"],
        photos: ["lamp"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ie"],
        select: 0,
        phonemes: ["ī"],
        background: ["vowel-wall"],
        photos: ["pie"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ow"],
        select: 0,
        phonemes: ["ō"],
        background: ["vowel-wall"],
        photos: ["flow"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["oa"],
        select: 0,
        phonemes: ["ō"],
        background: ["vowel-wall"],
        photos: ["boat"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["oe"],
        select: 0,
        phonemes: ["ō"],
        background: ["vowel-wall"],
        photos: ["hoe"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ey"],
        select: 0,
        phonemes: ["ē", "ā"],
        background: ["vowel-wall"],
        photos: ["key", "they"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["eigh"],
        select: 0,
        phonemes: ["ā"],
        background: ["vowel-wall"],
        photos: ["weigh"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ai"],
        select: 0,
        phonemes: ["ā"],
        background: ["vowel-wall"],
        photos: ["train"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "visual-review-overview",
    content: {
      graphemes: {
        graphemes: ["ay"],
        select: 0,
        phonemes: ["ā"],
        background: ["vowel-wall"],
        photos: ["hay"],
        slideType: "visual-review",
      },
    },
  },
  {
    slideType: "guided-phonics-review",
    content: {
      reviewName: "(auditory)",
      background: "review-1",
    },
    notes: [
      {
        header: "Auditory Review",
        list: [
          "1: /ēr/ (eer, ear) - 2",
          "2: /er/ (er, ir, ur, or, ar) - 5",
          "3: /or/ (or, our, ore) - 3",
          "4: /ĕr/ (are, air, ear) - 3",
          "5: /ar/ (ar) - 1",
          "6: /ī/ (i, y, igh, ie) - 4",
          "7: /ō/ (o, ow, oa, oe) - 4",
          "8: /ā/ (a, ai, ay, ey, eigh) - 5",
          "9: /ē/ (e, y, ee, ea, ey) - 5",
        ],
      },
    ],
  },
  { randomize: 9 },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["eer", "ear"],
        select: 1,
        phonemes: ["ēr"],
        background: ["vowel-wall", "consonant-wall"],
        photos: ["deer", "ear"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["er", "ir", "ur", "or", "ar"],
        select: 2,
        phonemes: ["er"],
        background: ["vowel-wall"],
        photos: ["ladder", "bird", "fur", "worm", "dollar"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["or", "our", "ore"],
        select: 3,
        phonemes: ["or"],
        background: ["vowel-wall"],
        photos: ["short", "four", "tore"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["are", "air", "ear"],
        select: 4,
        phonemes: ["ĕr"],
        background: ["vowel-wall", "consonant-wall"],
        photos: ["flare", "hair", "bear"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["ar"],
        select: 5,
        phonemes: ["ar"],
        background: ["vowel-wall"],
        photos: ["barn"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["i", "y", "igh", "ie"],
        select: 6,
        phonemes: ["ī"],
        background: ["vowel-wall"],
        photos: ["idea", "fly", "lamp", "pie"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["o", "ow", "oa", "oe"],
        select: 7,
        phonemes: ["ō"],
        background: ["vowel-wall"],
        photos: ["oval", "flow", "boat", "hoe"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["a", "ai", "ay", "ey", "eigh"],
        select: 8,
        phonemes: ["ā"],
        background: ["vowel-wall"],
        photos: ["apron", "train", "hay", "hey", "weigh"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "auditory-review-overview",
    content: {
      graphemes: {
        graphemes: ["e", "y", "ee", "ea", "ey"],
        select: 9,
        phonemes: ["ē"],
        background: ["vowel-wall"],
        photos: ["athlete", "city", "sheep", "leaf", "hey"],
        slideType: "auditory-review",
      },
    },
  },
  {
    slideType: "guided-phonics-review",
    content: {
      reviewName: "(blending)",
      background: "review-1",
    },
  },
  {
    slideType: "previously-blended",
    content: {
      words: [
        "worst",
        "cellar",
        "flavor",
        "grammar",
        "rumor",
        "purge",
        "burst",
        "nerve",
        "sworn",
        "squirm",
      ],
      amount: 10,
      pattern: ["ar", "ur", "er", "or", "ir"],
      nook: 10,
    },
  },
  {
    slideType: "guided-phonics-target",
    content: {
      liList: [
        "We are learning to read and write...",
        "... and identify six syllable types—<bold>specifically open</bold>. <gray>(2.R.3a)</gray>",
        '... common irregular "heart" words. <gray>(2.R.3k)</gray>',
      ],
      scList: [
        "I will know I can do this when I can...",
        "...blend and spell words with the <bold>open syllable type</bold>, including the high-frequency words <bold>toward, together</bold>.",
        "...<bold>read grade-level text</bold> that includes words with the target <bold>open syllables</bold> and this week's heart words: <bold>toward, together</bold>",
      ],
      pictures: [{ picture: "tiger", graphemes: ["i", "a", "e", "o", "u"] }],
    },
  },
  {
    slideType: "time-to-learn",
    content: {
      reviewName: "(blending)",
    },
  },
  {
    slideType: "learn-grapheme",
    content: {
      grapheme: "ti•ger",
      photo: "tiger",
    },
  },
  {
    slideType: "time-to-blend",
  },
  {
    slideType: "blending-routine",
    content: {
      word: "robot",
      grouping: ["ro", "bot"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "hotel",
      grouping: ["ho", "tel"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "music",
      grouping: ["mu", "sic"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "donut",
      grouping: ["do", "nut"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "basic",
      grouping: ["ba", "sic"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "moment",
      grouping: ["mo", "ment"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "pretend",
      grouping: ["pre", "tend"],
      syllableType: ["o", "c"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "spider",
      grouping: ["spi", "der"],
      syllableType: ["o", "rr"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "paper",
      grouping: ["pa", "per"],
      syllableType: ["o", "rr"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "before",
      grouping: ["be", "fore"],
      syllableType: ["o", "rr"],
      level: 7,
      photoVar: "",
    },
  },
  {
    slideType: "blending-routine",
    content: {
      word: "return",
      grouping: ["re", "turn"],
      syllableType: ["o", "rr"],
      level: 7,
      photoVar: "",
    },
  },
];
