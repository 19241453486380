import React, { useEffect } from "react";

import "./TimeToBlend.scss";

const TimeToBlend = ({ name, fullscreen, slide, setAnimationMaxCount }) => {
  const { content = {} } = slide;
  const { background = "material-2" } = content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`blend-slide-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`blend-slide-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
      }}
    >
      <div className="blend-overlay">
        <ul className="blend-title">
          <li>Time To blend</li>
        </ul>
      </div>
    </div>
  );
};

export default TimeToBlend;
