import React, { useEffect } from "react";
import "./GuidedPhonicsListen.scss";

const GuidedPhonicsListen = ({ name, fullscreen, setAnimationMaxCount }) => {
  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);
  return (
    <div
      className={`listen-slide-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`listen-slide-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/listen.webp)`,
      }}
    >
      <div className="listen-overlay">
        <ul className="listen-title">
          <li>Let's listen,</li>
          <li>break apart,</li>
          <li>and blend</li>
        </ul>
      </div>
    </div>
  );
};

export default GuidedPhonicsListen;
