export const data = [
  [".", "Consonant Sounds", "", "", "", "", "", "."],
  [
    ".",
    "Lips Together",
    "Teeth on Lip",
    "Tongue Between Teeth",
    "Tongue on Ridge Behind Teeth",
    "Tongue Pulled Back on Roof of Mouth",
    "Back of Throat",
    "Glottis",
  ],
  ["Stops", "s1", "+", "+", "s2", "+", "s3", "+"],
  ["Nasals", "n1", "+", "+", "n2", "+", "n3", "+"],
  ["Fricatives", "+", "f1", "f2", "f3", "f4", "+", "f5"],
  ["Affricates", "+", "+", "+", "+", "a1", "+", "+"],
  ["Glides", "g1", "+", "+", "+", "g2", "+", "+"],
  ["Liquids", "+", "+", "+", "l1", "l2", "+", "+"],
];

export const typeArr = [
  "stops",
  "nasals",
  "fricatives",
  "affricates",
  "glides",
  "liquids",
];

export const selectCell = ({
  cell,
  setGraphemes,
  setVoiced,
  setFaceImage,
  setItemImage,
  setStyleOverride,
}) => {
  const oneRight = [{ top: "-175%", left: "90%", height: "400%" }];
  const oneAboveBelow = [
    { top: "-400%", left: "-15%", height: "400%" },
    { top: "100%", left: "-25%", height: "400%" },
  ];
  const fricatives = [
    { top: "-150%", left: "-15%", height: "150%" },
    { top: "100%", left: "-25%", height: "150%" },
  ];

  switch (cell) {
    case "s1":
      setGraphemes(["/p/", "/b/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("pbFace");
      setItemImage(["popcorn", "bat"]);
      break;
    case "s2":
      setGraphemes(["/t/", "/d/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("tdFace");
      setItemImage(["turtle", "dolphin"]);
      break;
    case "s3":
      setGraphemes(["/k/", "/g/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("kgFace");
      setItemImage(["kite2", "go"]);
      break;
    case "n1":
      setGraphemes(["/m/"]);
      setVoiced(["voiced"]);
      setFaceImage("mFace");
      setItemImage(["map"]);
      setStyleOverride(oneRight);
      break;
    case "n2":
      setGraphemes(["/n/"]);
      setVoiced(["voiced"]);
      setFaceImage("nFace");
      setItemImage(["nest"]);
      setStyleOverride(oneRight);
      break;
    case "n3":
      setGraphemes(["/ng/"]);
      setVoiced(["voiced"]);
      setFaceImage("ngFace");
      setItemImage(["sing"]);
      setStyleOverride(oneRight);
      break;
    case "f1":
      setGraphemes(["/f/", "/v/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("fvFace");
      setItemImage(["fire", "volcano"]);
      setStyleOverride(fricatives);
      break;
    case "f2":
      setGraphemes(["/th/", "/TH/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("thFace");
      setItemImage(["thumb", "feather"]);
      setStyleOverride(fricatives);
      break;
    case "f3":
      setGraphemes(["/s/", "/z/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("szFace");
      setItemImage(["sun2", "zipper"]);
      setStyleOverride([
        { top: "-110%", left: "50%", height: "150%" },
        { top: "100%", left: "-25%", height: "150%" },
      ]);
      break;
    case "f4":
      setGraphemes(["/sh/", "/zh/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("shzhFace");
      setItemImage(["shell", "treasure"]);
      setStyleOverride([
        { top: "-100%", left: "5%", height: "100%"},
        { top: "10%", left: "100%", height: "100%" },
      ]);
      break;
    case "f5":
      setGraphemes(["/h/"]);
      setVoiced(["unvoiced"]);
      setFaceImage("hFace");
      setItemImage(["hat"]);
      setStyleOverride([...oneAboveBelow.reverse()]);
      break;
    case "a1":
      setGraphemes(["/ch/", "/j/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("chjFace");
      setItemImage(["cheese", "jump"]);
      break;
    case "g1":
      setGraphemes(["/wh/", "/w/"]);
      setVoiced(["unvoiced", "voiced"]);
      setFaceImage("whwFace");
      setItemImage(["whale2", "window"]);
      break;
    case "g2":
      setGraphemes(["/y/"]);
      setVoiced(["voiced"]);
      setFaceImage("yFace");
      setItemImage(["yo-yo"]);
      setStyleOverride(oneRight);
      break;
    case "l1":
      setGraphemes(["/l/"]);
      setVoiced(["voiced"]);
      setFaceImage("lFace");
      setItemImage(["lemon"]);
      setStyleOverride(oneAboveBelow);
      break;
    case "l2":
      setGraphemes(["/r/"]);
      setVoiced(["voiced"]);
      setFaceImage("rFace");
      setItemImage(["rose"]);
      setStyleOverride(oneRight);
      break;
    default:
  }
};
