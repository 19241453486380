import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import "./AVOverview.scss";

const AVOverview = ({
  name,
  fullscreen,
  slide,
  addData,
  setAnimationMaxCount,
}) => {
  const [deny, setDeny] = useState(false);
  const { graphemes: graph } = slide.content;
  const { slideType: sType } = slide;
  const {
    slideType,
    phonemes,
    background,
    graphemes,
    photos,
    select,
    nook = "nook-16",
  } = graph;
  const notify = () => toast("Slide added!");
  const toBe = () => toast("Coming soon!");

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`visual-review-overview-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`visual-review-overview-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${nook}.webp)`,
      }}
    >
      <h1 className="overview-title">
        {sType === "visual-review-overview"
          ? "Super Duper Speak Sounds"
          : "Speedy Written Graphemes"}
      </h1>

      <div className="grapheme-item">
        <div className="grapheme-main">
          {sType === "visual-review-overview" ? graphemes[select] : `${select}`}
        </div>
        <div className="bounty">
          Bounty:{" "}
          {sType === "visual-review-overview"
            ? phonemes.length
            : graphemes.length}
        </div>

        <div className="grapheme-buttons">
          <button
            className="info-button"
            onClick={() => {
              toBe();
            }}
          >
            Details
          </button>

          <button
            className="add-slide-button"
            disabled={deny}
            onClick={() => {
              if (deny === false) {
                setDeny(!deny);
                notify();
                addData({
                  slideType: slideType,
                  content: {
                    phonemes: phonemes,
                    background: background,
                    graphemes:
                      sType === "visual-review-overview"
                        ? [graphemes[select]]
                        : graphemes,
                    photos: photos,
                  },
                });
              }
            }}
          >
            Add Slide
          </button>
        </div>
      </div>
    </div>
  );
};

export default AVOverview;
