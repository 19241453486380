import React, { useEffect } from "react";

import "./LearnGrapheme.scss";

const LearnGrapheme = ({ name, fullscreen, slide, setAnimationMaxCount }) => {
  const { content } = slide;
  const {
    grapheme,
    photo,
    background = "material-1",
    reverse = false,
  } = content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`learn-grapheme-slide-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`learn-grapheme-slide-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
      }}
    >
      <div
        className="learn-grapheme-overlay"
        style={reverse === true ? { flexDirection: "row-reverse" } : {}}
      >
        <div className="grapheme-side">
          <div className="grapheme-box">{grapheme}</div>
        </div>
        <div className="photo-side">
          <img
            src={`https://photos.earlyreader.ai/${photo}.webp`}
            alt={photo}
            className="grapheme-photo"
          />
        </div>
      </div>
    </div>
  );
};

export default LearnGrapheme;
