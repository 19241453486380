import React, { useEffect } from "react";

import "./TimeToLearn.scss";

const TimeToLearn = ({ name, fullscreen, slide, setAnimationMaxCount }) => {
  const { content } = slide;
  const { background = "learn-1" } = content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`learn-slide-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`learn-slide-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
      }}
    >
      <div className="learn-overlay">
        <ul className="learn-title">
          <li>Time To Learn</li>
        </ul>
      </div>
    </div>
  );
};

export default TimeToLearn;
