import arFace from "../Assets/SoundPhotos/arFace.webp";
import awFace from "../Assets/SoundPhotos/awFace.webp";
import chjFace from "../Assets/SoundPhotos/chjFace.webp";
import erFace from "../Assets/SoundPhotos/erFace.webp";
import fvFace from "../Assets/SoundPhotos/fvFace.webp";
import hFace from "../Assets/SoundPhotos/hFace.webp";
import kgFace from "../Assets/SoundPhotos/kgFace.webp";
import lFace from "../Assets/SoundPhotos/lFace.webp";
import longAFace from "../Assets/SoundPhotos/longAFace.webp";
import longEFace from "../Assets/SoundPhotos/longEFace.webp";
import longIFace from "../Assets/SoundPhotos/longIFace.webp";
import longOFace from "../Assets/SoundPhotos/longOFace.webp";
import longUFace from "../Assets/SoundPhotos/longUFace.webp";
import mFace from "../Assets/SoundPhotos/mFace.webp";
import nFace from "../Assets/SoundPhotos/nFace.webp";
import ngFace from "../Assets/SoundPhotos/ngFace.webp";
import oiFace from "../Assets/SoundPhotos/oiFace.webp";
import ooFace from "../Assets/SoundPhotos/ooFace.webp";
import orFace from "../Assets/SoundPhotos/orFace.webp";
import ouFace from "../Assets/SoundPhotos/ouFace.webp";
import pbFace from "../Assets/SoundPhotos/pbFace.webp";
import rFace from "../Assets/SoundPhotos/rFace.webp";
import schwaFace from "../Assets/SoundPhotos/schwaFace.webp";
import shortAFace from "../Assets/SoundPhotos/shortAFace.webp";
import shortEFace from "../Assets/SoundPhotos/shortEFace.webp";
import shortIFace from "../Assets/SoundPhotos/shortIFace.webp";
import shortOFace from "../Assets/SoundPhotos/shortOFace.webp";
import shortUFace from "../Assets/SoundPhotos/shortUFace.webp";
import shzhFace from "../Assets/SoundPhotos/shzhFace.webp";
import szFace from "../Assets/SoundPhotos/szFace.webp";
import tdFace from "../Assets/SoundPhotos/tdFace.webp";
import thFace from "../Assets/SoundPhotos/thFace.webp";
import whwFace from "../Assets/SoundPhotos/whwFace.webp";
import yuFace from "../Assets/SoundPhotos/yuFace.webp";
import yFace from "../Assets/SoundPhotos/yFace.webp";

import itch from "../Assets/SoundPhotos/itch-n.webp";
import tree from "../Assets/SoundPhotos/tree-n.webp";
import cow from "../Assets/SoundPhotos/cow-n.webp";
import boy from "../Assets/SoundPhotos/boy-n.webp";
import train from "../Assets/SoundPhotos/train-n.webp";
import echo from "../Assets/SoundPhotos/echo-n.webp";
import apple from "../Assets/SoundPhotos/apple-n.webp";
import five from "../Assets/SoundPhotos/five-n.webp";
import banana from "../Assets/SoundPhotos/banana-n.webp";
import octopus from "../Assets/SoundPhotos/octopus-n.webp";
import up from "../Assets/SoundPhotos/up-n.webp";
import straw from "../Assets/SoundPhotos/straw-n.webp";
import boat from "../Assets/SoundPhotos/boat-n.webp";
import book from "../Assets/SoundPhotos/book-n.webp";
import spoon from "../Assets/SoundPhotos/spoon-n.webp";
import cube from "../Assets/SoundPhotos/cube-n.webp";
import shirt from "../Assets/SoundPhotos/shirt-n.webp";
import star from "../Assets/SoundPhotos/star-n.webp";
import corn from "../Assets/SoundPhotos/corn-n.webp";

import turtle from "../Assets/SoundPhotos/turtle-n.webp";
import bat from "../Assets/SoundPhotos/bat-n.webp";
import popcorn from "../Assets/SoundPhotos/popcorn-n.webp";
import kite2 from "../Assets/SoundPhotos/kite2-n.webp";
import go from "../Assets/SoundPhotos/go-n.webp";
import dolphin from "../Assets/SoundPhotos/dolphin-n.webp";
import map from "../Assets/SoundPhotos/map-n.webp";
import nest from "../Assets/SoundPhotos/nest-n.webp";
import sing from "../Assets/SoundPhotos/sing-n.webp";
import fire from "../Assets/SoundPhotos/fire-n.webp";
import thumb from "../Assets/SoundPhotos/thumb-n.webp";
import sun2 from "../Assets/SoundPhotos/sun2-n.webp";
import shell from "../Assets/SoundPhotos/shell-n.webp";
import hat from "../Assets/SoundPhotos/hat-n.webp";
import volcano from "../Assets/SoundPhotos/volcano-n.webp";
import feather from "../Assets/SoundPhotos/feather-n.webp";
import zipper from "../Assets/SoundPhotos/zipper-n.webp";
import treasure from "../Assets/SoundPhotos/treasure-n.webp";
import cheese from "../Assets/SoundPhotos/cheese-n.webp";
import jump from "../Assets/SoundPhotos/jump-n.webp";
import whale from "../Assets/SoundPhotos/whale2-n.webp";
import window from "../Assets/SoundPhotos/window-n.webp";
import rose from "../Assets/SoundPhotos/rose-n.webp";
import lemon from "../Assets/SoundPhotos/lemon-n.webp";
import yoYo from "../Assets/SoundPhotos/yo-yo-n.webp";

import stops from "../Assets/SoundPhotos/stops-n.webp";
import nasals from "../Assets/SoundPhotos/nasals-n.webp";
import fricatives from "../Assets/SoundPhotos/fricatives-n.webp";
import affricates from "../Assets/SoundPhotos/affricates-n.webp";
import glides from "../Assets/SoundPhotos/glides-n.webp";
import liquids from "../Assets/SoundPhotos/liquids-n.webp";

import speakerIcon from "../Assets/SoundPhotos/speakerIcon-n.webp";

export const getSoundPhoto = (name) => {
  switch (name) {
    case "arFace":
      return arFace;
    case "awFace":
      return awFace;
    case "chjFace":
      return chjFace;
    case "erFace":
      return erFace;
    case "fvFace":
      return fvFace;
    case "hFace":
      return hFace;
    case "kgFace":
      return kgFace;
    case "lFace":
      return lFace;
    case "longAFace":
      return longAFace;
    case "longEFace":
      return longEFace;
    case "longIFace":
      return longIFace;
    case "longOFace":
      return longOFace;
    case "longUFace":
      return longUFace;
    case "mFace":
      return mFace;
    case "nFace":
      return nFace;
    case "ngFace":
      return ngFace;
    case "oiFace":
      return oiFace;
    case "ooFace":
      return ooFace;
    case "orFace":
      return orFace;
    case "ouFace":
      return ouFace;
    case "pbFace":
      return pbFace;
    case "rFace":
      return rFace;
    case "schwaFace":
      return schwaFace;
    case "shortAFace":
      return shortAFace;
    case "shortEFace":
      return shortEFace;
    case "shortIFace":
      return shortIFace;
    case "shortOFace":
      return shortOFace;
    case "shortUFace":
      return shortUFace;
    case "shzhFace":
      return shzhFace;
    case "szFace":
      return szFace;
    case "tdFace":
      return tdFace;
    case "thFace":
      return thFace;
    case "whwFace":
      return whwFace;
    case "yuFace":
      return yuFace;
    case "yFace":
      return yFace;

    case "itch":
      return itch;
    case "tree":
      return tree;
    case "cow":
      return cow;
    case "boy":
      return boy;
    case "train":
      return train;
    case "echo":
      return echo;
    case "apple":
      return apple;
    case "five":
      return five;
    case "banana":
      return banana;
    case "octopus":
      return octopus;
    case "up":
      return up;
    case "straw":
      return straw;
    case "boat":
      return boat;
    case "book":
      return book;
    case "spoon":
      return spoon;
    case "cube":
      return cube;
    case "shirt":
      return shirt;
    case "star":
      return star;
    case "corn":
      return corn;

    case "turtle":
      return turtle;
    case "bat":
      return bat;
    case "dolphin":
      return dolphin;
    case "go":
      return go;
    case "kite2":
      return kite2;
    case "popcorn":
      return popcorn;
    case "map":
      return map;
    case "nest":
      return nest;
    case "sing":
      return sing;
    case "fire":
      return fire;
    case "thumb":
      return thumb;
    case "sun2":
      return sun2;
    case "shell":
      return shell;
    case "hat":
      return hat;
    case "volcano":
      return volcano;
    case "feather":
      return feather;
    case "zipper":
      return zipper;
    case "cheese":
      return cheese;
    case "jump":
      return jump;
    case "whale2":
      return whale;
    case "window":
      return window;
    case "lemon":
      return lemon;
    case "rose":
      return rose;
    case "yo-yo":
      return yoYo;
    case "treasure":
      return treasure;

    case "stops":
      return stops;
    case "nasals":
      return nasals;
    case "fricatives":
      return fricatives;
    case "affricates":
      return affricates;
    case "glides":
      return glides;
    case "liquids":
      return liquids;

    case "speakerIcon":
      return speakerIcon;
    default:
      return null;
  }
};
