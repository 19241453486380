import axios from "axios";

// Fetch lesson data
export const fetchLessonData = async (
  lesson_id,
  sessionParam,
  slideParam,
  setLesson,
  setCurrentSession,
  setCurrentSlide,
  setTotalSlides,
  setTotalSlidesInAllSessions,
  singleSlideLessonTypes
) => {
  try {
    const response = await axios.post("/graphql", {
      query: `
        query GetLessonSessionById($lesson_id: ID!) {
          getLessonSessionById(lesson_id: $lesson_id) {
            lesson_id
            title
            description
            content
          }
        }
      `,
      variables: { lesson_id },
    });

    const data = response.data.data.getLessonSessionById;
    setLesson(data);
    setCurrentSession(sessionParam);
    setCurrentSlide(slideParam);

    let totalSlidesAllSessions = 0;
    let calculatedSlides = 1;

    data.content.forEach((session, index) => {
      let sessionSlideCount = singleSlideLessonTypes.includes(
        session.lesson_type
      )
        ? 1
        : session.content.length;

      if (index === sessionParam - 1 && calculatedSlides === 1) {
        calculatedSlides = sessionSlideCount;
      }

      totalSlidesAllSessions += sessionSlideCount;
    });

    setTotalSlides(calculatedSlides);
    setTotalSlidesInAllSessions(totalSlidesAllSessions);
  } catch (error) {
    console.error("Error fetching lesson data", error);
  }
};

// Process randomize/skip logic in lesson content
export const processLessonSpecials = (data) => {
  const processed = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (typeof item.randomize === "number") {
      const count = item.randomize;
      const toShuffle = data.slice(i + 1, i + 1 + count);
      const shuffled = [...toShuffle].sort(() => Math.random() - 0.5);
      processed.push(...shuffled);
      i += count;
    } else if (typeof item.skip === "number") {
      i += item.skip;
    } else {
      processed.push(item);
    }
  }
  return processed;
};

// Dynamic next navigation with animations
export const handleNextSlide = (
  lessonData,
  currentSlide,
  setCurrentSlide,
  animation,
  animationMax,
  setAnimation
) => {
  const current = lessonData[currentSlide];
  if (!current) return;

  if (animation >= animationMax) {
    if (currentSlide < lessonData.length - 1) {
      setCurrentSlide(currentSlide + 1);
      setAnimation(0);
    }
  } else {
    setAnimation(animation + 1);
  }
};

// Dynamic previous navigation with animations
export const handlePrevSlide = (
  currentSlide,
  setCurrentSlide,
  animation,
  setAnimation
) => {
  if (animation > 0) {
    setAnimation(0);
  } else if (currentSlide > 0) {
    setCurrentSlide(currentSlide - 1);
    setAnimation(0);
  }
};
