import React from "react";

import AuditoryReview from "./LessonRenderComponents/GuidedPhonics/AuditoryReview/AuditoryReview";
import VisualReview from "./LessonRenderComponents/GuidedPhonics/VisualReview/VisualReview";
import GuidedPhonicsLesson from "./LessonRenderComponents/GuidedPhonics/Lesson/GuidedPhonicsLesson";
import GuidedPhonicsTarget from "./LessonRenderComponents/GuidedPhonics/Target/GuidedPhonicsTarget";
import GuidedPhonicsListen from "./LessonRenderComponents/GuidedPhonics/Listen/GuidedPhonicsListen";
import GuidedPhonicsReview from "./LessonRenderComponents/GuidedPhonics/Review/GuidedPhonicsReview";
import AVOverview from "./LessonRenderComponents/GuidedPhonics/AVOverview/AVOverview";
import PreviouslyBlended from "./LessonRenderComponents/GuidedPhonics/PreviouslyBlended/PreviouslyBlended";
import TimeToLearn from "./LessonRenderComponents/GuidedPhonics/Learn/TimeToLearn";
import LearnGrapheme from "./LessonRenderComponents/GuidedPhonics/LearnGrapheme/LearnGrapheme";
import TimeToBlend from "./LessonRenderComponents/GuidedPhonics/Blend/TimeToBlend";
import BlendingRoutine from "./LessonRenderComponents/GuidedPhonics/BlendWord/BlendingRoutine";

import "./LessonTypeRenderer.scss";

const LessonSlideRenderer = ({
  slide,
  name,
  fullscreen,
  animation,
  addData,
  setAnimationMaxCount,
  setAnimation,
}) => {
  switch (slide.slideType) {
    case "auditory-review":
      return (
        <AuditoryReview
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "visual-review":
      return (
        <VisualReview
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "auditory-review-overview":
    case "visual-review-overview":
      return (
        <AVOverview
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          addData={addData}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "guided-phonics-lesson":
      return (
        <GuidedPhonicsLesson
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "guided-phonics-target":
      return (
        <GuidedPhonicsTarget
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "guided-phonics-listen":
      return (
        <GuidedPhonicsListen
          name={name}
          fullscreen={fullscreen}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "guided-phonics-review":
      return (
        <GuidedPhonicsReview
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "previously-blended":
      return (
        <PreviouslyBlended
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "time-to-learn":
      return (
        <TimeToLearn
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "learn-grapheme":
      return (
        <LearnGrapheme
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    case "time-to-blend": {
      return (
        <TimeToBlend
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
        />
      );
    }
    case "blending-routine": {
      return (
        <BlendingRoutine
          slide={slide}
          name={name}
          fullscreen={fullscreen}
          animation={animation}
          setAnimationMaxCount={setAnimationMaxCount}
          setAnimation={setAnimation}
        />
      );
    }
    default:
      return <div>Unknown Lesson Type</div>;
  }
};

export default LessonSlideRenderer;
