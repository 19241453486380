import React, { useMemo, useEffect } from "react";
import "./PreviouslyBlended.scss";

const PreviouslyBlended = ({
  slide,
  name,
  fullscreen,
  animation,
  setAnimationMaxCount,
}) => {
  useEffect(() => {
    setAnimationMaxCount(1);
  }, []);

  const { words, amount, pattern, nook } = slide.content;

  // Shuffle and limit to `amount` words, memoized to keep it consistent between renders
  const selectedWords = useMemo(() => {
    const shuffled = [...words].sort(() => Math.random() - 0.5);
    const chosen = shuffled.slice(0, amount);
    return chosen.sort((a, b) => a.localeCompare(b));
  }, [words, amount]);

  // Helper: apply <b> to pattern if animation === 0, remove if === 1
  const highlightWord = (word) => {
    let display = word;

    pattern.forEach((p) => {
      const regex = new RegExp(p.replace(/_/g, "."), "gi"); // handle o_e etc.
      if (animation === 0) {
        display = display.replace(regex, (match) => `<b>${match}</b>`);
      } else {
        display = display.replace(/<b>(.*?)<\/b>/gi, "$1"); // remove all <b>
      }
    });

    return display;
  };

  return (
    <div
      className={`blending-review-overview-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`blending-review-overview-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/nook-${nook}.webp)`,
      }}
    >
      <h1 className="blending-title">Previously Blended Words</h1>
      <div className="blending-table">
        {Array.from({ length: Math.ceil(selectedWords.length / 3) }).map(
          (_, rowIdx) => (
            <div className="blending-row" key={`row-${rowIdx}`}>
              {selectedWords
                .slice(rowIdx * 3, rowIdx * 3 + 3)
                .map((word, colIdx) => (
                  <div
                    className="blending-cell"
                    key={`cell-${rowIdx}-${colIdx}`}
                    dangerouslySetInnerHTML={{
                      __html: highlightWord(word),
                    }}
                  />
                ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PreviouslyBlended;
