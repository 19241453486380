import React, { useEffect } from "react";

import { getPhotoLayoutClass } from "../../../LessonTypeRenderer.logic";
import VowelValley from "../../../../PhonemeTables/VowelValley";
import ConsonantWall from "../../../../PhonemeTables/ConsonantSounds";

import "./AuditoryReview.scss";

const AuditoryReview = ({
  slide,
  name,
  fullscreen,
  animation,
  setAnimationMaxCount,
}) => {
  const { content, slideType } = slide;
  const { photos, background, graphemes, phonemes } = content;

  useEffect(() => {
    setAnimationMaxCount(1);
  }, []);

  return (
    <div
      className={`audio-review-consonant ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`audio-review-consonant-${name}`}
    >
      <div className="arc-slide-content">
        {/* Grapheme Tiles on Left Side */}
        <div
          className={`grapheme-container ${
            graphemes.length >= 3 ? "two-columns" : "single-column"
          } ${animation === 1 ? "gc-fade-in" : "gc-no-fade"}`}
        >
          {graphemes.map((grapheme, index) => (
            <div
              key={`grapheme-${index}`}
              className={`grapheme-tile ${fullscreen ? "gt-fullscreen" : ""}`}
            >
              {grapheme}
            </div>
          ))}
        </div>
      </div>

      {/* Backgrounds Layered */}
      <div className={`background-container`}>
        {background.map((bg, index) => {
          if (bg === "vowel-wall") {
            return (
              <VowelValley
                key={`background-${index}`}
                highlights={phonemes}
                half={background.length === 2}
                slideType={slideType}
                fullscreen={fullscreen}
              />
            );
          } else {
            return (
              <ConsonantWall
                key={`background-${index}`}
                highlights={phonemes}
                slideType={slideType}
                half={background.length === 2}
                top={slideType === "visual-review" ? "50%" : ""}
                fullscreen={fullscreen}
              />
            );
          }
        })}
      </div>

      {/* Photos on Right Side */}
      <div
        className={`photo-container ${getPhotoLayoutClass(photos.length)} ${
          animation === 1 ? "photo-container-right" : ""
        }`}
      >
        {photos.map((photo, index) => {
          // console.log(photos);
          return (
            <img
              key={`photo-${index}`}
              src={`https://photos.earlyreader.ai/${photo}.webp`}
              alt={`photo-${index}`}
              className={`photo-tile photo-${index + 1}`}
            />
          );
        })}
      </div>

      {/* Bounty Bar */}
      <div
        className={`block-wall ${animation === 1 ? "block-wall-right" : ""}`}
      />
      <div className="bounty-bar">
        <h1>Bounty: {graphemes.length}</h1>
      </div>
    </div>
  );
};

export default AuditoryReview;
