import React from "react";
import { Textfit } from "react-textfit";

import ConsonantSoundCell from "./Cells/ConsonantSoundCell";
import { getSoundPhoto } from "../../Utils/Phones/soundPositions";
import { data, typeArr } from "./ConsonantSounds.logic";

import "./ConsonantSounds.scss";

const ConsonantSounds = ({
  highlights = [],
  half,
  top = "",
  right = "",
  slideType,
  fullscreen,
}) => {
  return (
    <div
      className={`consonant-sounds-wrapper ${
        slideType === "audio-review" && half ? "half-height" : ""
      } ${slideType === "visual-review" && half ? "half-width" : ""}`}
      style={{ top: top, right: right }}
    >
      <div className="consonant-sounds-grid">
        {data.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            const key = `${rowIndex}-${colIndex}`;
            let className = "";

            if (cell === "") {
              return null;
            }
            if (cell === ".") {
              return (
                <div key={key} className={`cell no-border ${className}`} />
              );
            }
            if (cell === "+") {
              return <div key={key} className={`cell empty ${className}`} />;
            }
            if (/^[A-Za-z][0-9]$/.test(cell)) {
              return (
                <ConsonantSoundCell
                  key={key}
                  className={`cell cell-tile ${className}`}
                  cell={cell}
                  fullscreen={fullscreen}
                  highlights={highlights}
                />
              );
            }

            if (rowIndex === 0 && colIndex === 1) {
              className = "merge-6x1 header";
            }

            return (
              <Textfit key={key} className={`cell text ${className}`}>
                {cell}
                {typeArr.includes(cell.toLowerCase()) && (
                  <img
                    key={`text-fit-${key}`}
                    className="type-photo"
                    src={getSoundPhoto(cell.toLowerCase())}
                    alt={cell.toLowerCase()}
                  />
                )}
              </Textfit>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ConsonantSounds;
