import React, { useEffect } from "react";
import "./GuidedPhonicsReview.scss";

const GuidedPhonicsReview = ({
  name,
  fullscreen,
  slide,
  setAnimationMaxCount,
}) => {
  const { content } = slide;
  const { reviewName, background } = content;

  useEffect(() => {
    setAnimationMaxCount(0);
  }, []);

  return (
    <div
      className={`review-slide-container ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`review-slide-${name}`}
      style={{
        backgroundImage: `url(https://photos.earlyreader.ai/${background}.webp)`,
      }}
    >
      <div className="review-overlay">
        <ul className="review-title">
          <li>Let's review</li>
          <li>{reviewName}</li>
        </ul>
      </div>
    </div>
  );
};

export default GuidedPhonicsReview;
