import React from "react";

import { data } from "./VowelValley.logic";
import { getSoundPhoto } from "../../Utils/Phones/soundPositions";

import "./VowelValley.scss";
import { Textfit } from "react-textfit";

const VowelValley = ({
  highlights = [],
  half,
  top = "",
  right = "",
  slideType,
}) => {
  return (
    <div
      className={`vowel-valley-wrapper ${
        slideType === "audio-review" && half ? "half-height" : ""
      } ${slideType === "visual-review" && half ? "half-width" : ""}`}
      style={{ top: top, right: right }}
    >
      <div className="vowel-valley-grid">
        {data.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            const normalizedCell = cell.replaceAll("/", "").trim();
            const isHighlighted = highlights.includes(normalizedCell);
            const key = `${rowIndex}-${colIndex}`;
            let className = "";

            if (cell === "merge") {
              return null;
            }

            if (rowIndex === 0 && colIndex === 4) {
              className = "merge-5x2 header";
            } else if (rowIndex === 0 && colIndex === 9) {
              className = "merge-1x2";
            } else if (rowIndex === 0 && colIndex === 10) {
              className = "merge-3x2 header-2";
            }

            if (cell === ".") {
              return <div key={key} className={`cell empty ${className}`} />;
            }

            const isImage = cell.startsWith("*");
            if (isImage) {
              const imageName = cell.replace("*", "");
              return (
                <div key={key} className={`cell image ${className}`}>
                  <img
                    className={`${
                      cell.includes("Face") ? "face-card" : "not-face"
                    }`}
                    src={
                      getSoundPhoto(imageName) ||
                      `https://photos.earlyreader.ai/${imageName}.webp`
                    }
                    alt={imageName}
                  />
                </div>
              );
            }

            return (
              <Textfit
                key={key}
                className={`cell text ${className} ${
                  isHighlighted ? "highlight" : ""
                }`}
              >
                {cell}
              </Textfit>
            );
          })
        )}
      </div>
    </div>
  );
};

export default VowelValley;
