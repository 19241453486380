import React from "react";
import { Link } from "react-router-dom";

import "./LessonSelect.scss";

const LessonSelect = () => {
  return (
    <div className="lesson-select">
      <h1 className="under-construction">Under Construction</h1>
      <h1>Lesson Select</h1>
      <p>Choose a lesson to start improving your reading skills.</p>
      <Link to="/lesson" className="feature-card">
        <p>Test Lesson</p>
      </Link>
    </div>
  );
};

export default LessonSelect;
