import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import logo from "../../Utils/Assets/AppIcon/logo192.png";
import { logout } from "../../Utils/Auth/auth";

import "./MajorNavbar.scss";

const MajorNavbar = ({ isLoggedIn }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 1140);
    if (window.innerWidth < 300) setShowSidebar(false);
    if (window.innerWidth >= 1140) setShowSidebar(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleNavigation = (route) => {
    navigate(route);
    if (isSmallScreen) {
      setShowSidebar(false);
    }
  };

  return (
    <nav className="major-navbar">
      <div className="brand">
        <img
          src={logo}
          alt="Two siblings reading together"
          className="app-icon"
        />
        <h1 onClick={() => handleNavigation("/")}>EarlyReaderAI</h1>
      </div>
      <ul className="menu">
        <li onClick={() => handleNavigation("/")}>Home</li>
        <li onClick={() => handleNavigation("/about")}>About</li>
        <li onClick={() => handleNavigation("/contact")}>Contact</li>
        {isLoggedIn ? (
          <>
            <li onClick={() => handleNavigation("/dashboard")}>Dashboard</li>
            <li onClick={() => handleNavigation("/profile")}>Profile</li>
            <li>
              <button onClick={logout}>Logout</button>
            </li>
          </>
        ) : (
          <>
            <li onClick={() => handleNavigation("/login")}>Login</li>
            <li onClick={() => handleNavigation("/signup")}>Sign Up</li>
          </>
        )}
      </ul>
      {isSmallScreen && (
        <>
          <FontAwesomeIcon
            icon={faBars}
            className="menu-icon"
            onClick={toggleSidebar}
          />
          {isSmallScreen && (
            <div className={`blur ${showSidebar ? "show" : ""}`} />
          )}
          {showSidebar && (
            <div className={`sidebar ${showSidebar ? "show" : ""}`}>
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={toggleSidebar}
              />
              <ul>
                <li onClick={() => handleNavigation("/")}>Home</li>
                <li onClick={() => handleNavigation("/about")}>About</li>
                <li onClick={() => handleNavigation("/contact")}>Contact</li>
                {isLoggedIn ? (
                  <>
                    <li onClick={() => handleNavigation("/dashboard")}>
                      Dashboard
                    </li>
                    <li onClick={() => handleNavigation("/profile")}>
                      Profile
                    </li>
                    <li>
                      <button onClick={logout}>Logout</button>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => handleNavigation("/login")}>Login</li>
                    <li onClick={() => handleNavigation("/signup")}>Sign Up</li>
                  </>
                )}
              </ul>
            </div>
          )}
        </>
      )}
    </nav>
  );
};

export default MajorNavbar;
