export const getPhotoLayoutClass = (count) => {
  if (count === 1) return "layout-1";
  if (count === 2) return "layout-2";
  if (count === 3) return "layout-3";
  if (count === 4) return "layout-4";
  if (count === 5) return "layout-5";
  if (count === 6) return "layout-6";
  if (count === 7) return "layout-7";
  if (count >= 8) return "layout-8";
  return "";
};
