import {
  getPhonemes,
  convertCmuToIpa,
  mapIpaToGraphemes,
} from "../../../../../Utils/Phones/phones";

const longVowelMap = {
  a: "eɪ",
  e: "i",
  i: "aɪ",
  o: "oʊ",
  u: "ju",
  u2: "u",
};
const vowelSet = new Set([
  "i",
  "ɪ",
  "eɪ",
  "ɛ",
  "æ",
  "aɪ",
  "ɔ",
  "ʌ",
  "ɑ",
  "oʊ",
  "ʊ",
  "u",
  "ju",
  "ə",
  "ɔɪ",
  "aʊ",
  "ɹ̩",
  "ɑɹ",
  "ɔɹ",
]);
const schwaSet = new Set(["ə"]);
const affricateSet = new Set(["t͡ʃ", "d͡ʒ", "j", "wh", "w"]);
const stopSet = new Set(["p", "b", "t", "d", "k", "g", "h"]);
const specialCombinations = [
  "all",
  "anc",
  "ang",
  "ank",
  "ell",
  "ild",
  "ill",
  "inc",
  "ind",
  "ing",
  "ink",
  "oll",
  "old",
  "olt",
  "onc",
  "ong",
  "onk",
  "ost",
  "ull",
  "unc",
  "ung",
  "unk",
  "ab",
  "ad",
  "ag",
  "am",
  "an",
  "ap",
  "at",
  "ed",
  "en",
  "et",
  "id",
  "ig",
  "im",
  "in",
  "ip",
  "it",
  "ob",
  "og",
  "op",
  "ot",
  "ub",
  "ug",
  "um",
  "un",
  "ut",
  "mp",
];
const consonantBlends = [
  "shr",
  "spl",
  "spr",
  "str",
  "thr",
  "bl",
  "br",
  "cl",
  "cr",
  "dr",
  "fl",
  "fr",
  "gl",
  "gr",
  "pl",
  "pr",
  "sc",
  "sk",
  "sl",
  "sm",
  "sn",
  "sp",
  "st",
  "sw",
  "tr",
  "tw",
  "wr",
];

export const mapSyllableStringsToTileIndices = (
  groupingStrings = [],
  graphemeTiles = []
) => {
  if (groupingStrings.length < 1 || graphemeTiles < 1) {
    return [];
  }

  const lowerTiles = graphemeTiles.map((tile) => tile.toLowerCase());
  const groups = [];

  let tileIndex = 0;
  let tileOffset = 0;
  let globalCharIndex = 0;

  for (let g = 0; g < groupingStrings.length; g++) {
    const syllable = groupingStrings[g].toLowerCase();
    const groupIndices = [];
    let localCharIndex = 0;

    while (tileIndex < lowerTiles.length && localCharIndex < syllable.length) {
      const tile = lowerTiles[tileIndex];
      const charsNeeded = syllable.slice(
        localCharIndex,
        localCharIndex + tile.length - tileOffset
      );
      const matches = tile.startsWith(charsNeeded);

      if (matches) {
        if (tileOffset === 0 && charsNeeded.length === tile.length) {
          groupIndices.push(tileIndex);
        } else {
          groupIndices.push(tileIndex + 0.5);
        }

        localCharIndex += charsNeeded.length;
        tileOffset += charsNeeded.length;

        if (tileOffset >= tile.length) {
          tileIndex++;
          tileOffset = 0;
        }
      } else {
        tileIndex++;
        tileOffset = 0;
      }

      globalCharIndex += charsNeeded.length;
    }

    groups.push(groupIndices);
  }

  return groups;
};

export const fetchPhonemeTiles = async (word) => {
  const cmuPhonemes = await getPhonemes(word);
  const flattenedPhonemes = [...cmuPhonemes];
  const ipaPhonemes = convertCmuToIpa(flattenedPhonemes);
  const graphemeTiles = mapIpaToGraphemes(ipaPhonemes, word);

  const mergeSilentEAfter = ["v", "s", "c", "g"];

  for (let i = 1; i < graphemeTiles.length; i++) {
    const current = graphemeTiles[i];
    const prev = graphemeTiles[i - 1];

    if (
      current === "(e)" &&
      mergeSilentEAfter.includes(prev.slice(-1).toLowerCase())
    ) {
      const ipaIndex = i - 2 >= 0 ? i - 2 : 0;
      const vowel = graphemeTiles[ipaIndex]?.toLowerCase();
      const ipa = ipaPhonemes[ipaIndex];

      if (!(vowel && ipa && longVowelMap[vowel] === ipa)) {
        graphemeTiles[i - 1] = prev + "e";
        graphemeTiles.splice(i--, 1);
      }
    } else if (current === "(e)" && prev.slice(-1).toLowerCase() === "l") {
      graphemeTiles[i] = current.replace(/[()]/g, "");
    } else if (current === "(r)") {
      graphemeTiles[i - 1] = prev + "r";
      graphemeTiles.splice(i--, 1);
    }
  }

  return {
    flattenedPhonemes,
    ipaPhonemes,
    graphemeTiles,
  };
};

export const getVowelIndex = (ipaPhonemes) =>
  ipaPhonemes.findIndex((s) => vowelSet.has(s));

export const getBlendingPhase = (phonemeData, animation, level = 2) => {
  const { ipaPhonemes, graphemeTiles, syllableType, grouping } = phonemeData;
  const vowelIndex = getVowelIndex(ipaPhonemes);
  const totalPhonemes = ipaPhonemes.length;

  const phonemeToGraphemeMap = [];
  const tileTypes = [];
  let gIndex = 0;

  for (let i = 0; i < ipaPhonemes.length; i++) {
    while (graphemeTiles[gIndex]?.includes("(")) tileTypes[gIndex++] = "silent";

    const currentGrapheme = graphemeTiles[gIndex];
    const nextGrapheme = graphemeTiles[gIndex + 1];
    const nextNextGrapheme = graphemeTiles[gIndex + 2];

    if (
      currentGrapheme?.length === 1 &&
      !vowelSet.has(ipaPhonemes[i]) &&
      nextGrapheme?.toLowerCase() === "l" &&
      nextNextGrapheme?.toLowerCase() === "e" &&
      schwaSet.has(ipaPhonemes[i + 1])
    ) {
      phonemeToGraphemeMap[i] = gIndex;
      tileTypes[gIndex] = "le-consonant";
      phonemeToGraphemeMap.push(gIndex + 1, gIndex + 2);
      tileTypes[gIndex + 1] = "le-special no-border";
      tileTypes[gIndex + 2] = "le-special";
      gIndex += 3;
      i += 2;
    } else {
      phonemeToGraphemeMap[i] = gIndex;
      tileTypes[gIndex] = schwaSet.has(ipaPhonemes[i])
        ? "schwa"
        : vowelSet.has(ipaPhonemes[i])
        ? "vowel"
        : "consonant";
      gIndex++;
    }
  }

  const buildFullPhase = () => {
    const indices = Array.from({ length: graphemeTiles.length }, (_, i) => i);
    return {
      activeTiles: indices,
      arrowSpan: [0, indices.length - 1],
      growTiles: true,
    };
  };

  const phase = {
    activeTiles: [],
    arrowSpan: null,
    showImage: false,
    growTiles: false,
    level,
    tileTypes,
  };

  if (level === 1) {
    if (animation >= 1 && animation <= totalPhonemes) {
      const gIdx = phonemeToGraphemeMap[animation - 1];
      phase.activeTiles = [gIdx];
      phase.arrowSpan = [gIdx, gIdx];
      const ipa = ipaPhonemes[animation - 1];
      phase.shouldAutoAdvance =
        animation < totalPhonemes &&
        (stopSet.has(ipa) || affricateSet.has(ipa));
    } else if (animation === totalPhonemes + 1)
      Object.assign(phase, buildFullPhase());
    else if (animation === totalPhonemes + 2) phase.showImage = true;
  }

  if (level === 2) {
    if (animation >= 1 && animation <= vowelIndex) {
      const gIdx = phonemeToGraphemeMap[animation - 1];
      phase.activeTiles = [gIdx];
      phase.arrowSpan = [gIdx, gIdx];
    } else if (animation === vowelIndex + 1) {
      const gIdx = phonemeToGraphemeMap[vowelIndex];
      phase.activeTiles = [gIdx];
      phase.arrowSpan = [gIdx, gIdx];
    } else if (animation === vowelIndex + 2) {
      const active = phonemeToGraphemeMap.slice(0, vowelIndex + 1);
      phase.activeTiles = active;
      phase.arrowSpan = [active[0], active.at(-1)];
    } else if (animation > vowelIndex + 2 && animation <= totalPhonemes + 1) {
      const gIdx = phonemeToGraphemeMap[animation - 2];
      phase.activeTiles = [gIdx];
      phase.arrowSpan = [gIdx, gIdx];
    } else if (animation === totalPhonemes + 2)
      Object.assign(phase, buildFullPhase());
    else if (animation === totalPhonemes + 3) phase.showImage = true;
  }

  if (level === 3) {
    const groups = getGroupedPhonemeGraphemeIndices(
      phonemeToGraphemeMap,
      graphemeTiles,
      tileTypes,
      phonemeToGraphemeMap[vowelIndex]
    );
    const totalGroups = groups.length;
    const vowelGIdx = phonemeToGraphemeMap[vowelIndex];
    const preVowelGroup = groups.findIndex((g) => g.includes(vowelGIdx));

    const blendUpToVowelStep = preVowelGroup + 2;

    if (animation === blendUpToVowelStep) {
      const groupUpToVowel = groups.slice(0, preVowelGroup + 1).flat();
      phase.activeTiles = groupUpToVowel;
      phase.arrowSpan = [groupUpToVowel[0], groupUpToVowel.at(-1)];
    } else if (animation >= 1 && animation <= totalGroups + 1) {
      const adjustedIndex =
        animation <= preVowelGroup + 1 ? animation - 1 : animation - 2;

      const active = groups[adjustedIndex];
      phase.activeTiles = active;
      phase.arrowSpan = [active[0], active.at(-1)];
      if (
        animation < totalGroups + 1 &&
        active.length === 1 &&
        !tileTypes[active[0]]?.includes("silent")
      ) {
        const ipaIdx = phonemeToGraphemeMap.findIndex(
          (idx) => idx === active[0]
        );
        const ipa = ipaPhonemes[ipaIdx];
        if (stopSet.has(ipa) || affricateSet.has(ipa)) {
          phase.shouldAutoAdvance = true;
        }
      }
    } else if (animation === totalGroups + 2) {
      Object.assign(phase, buildFullPhase());
    } else if (animation === totalGroups + 3) {
      phase.showImage = true;
    }
  }

  if (level === 4) {
    if (animation === 1) {
      const active = phonemeToGraphemeMap.slice(0, vowelIndex + 1);
      phase.activeTiles = active;
      phase.arrowSpan = [active[0], active.at(-1)];
    } else if (animation > 1 && animation <= totalPhonemes - vowelIndex) {
      const idx = vowelIndex + animation - 1;
      const gIdx = phonemeToGraphemeMap[idx];
      phase.activeTiles = [gIdx];
      phase.arrowSpan = [gIdx, gIdx];

      const ipa = ipaPhonemes[idx];
      if (stopSet.has(ipa) || affricateSet.has(ipa)) {
        phase.shouldAutoAdvance = true;
      }
    } else if (animation === totalPhonemes - vowelIndex + 1) {
      Object.assign(phase, buildFullPhase());
    } else if (animation === totalPhonemes - vowelIndex + 2) {
      phase.showImage = true;
    }
  }

  if (level === 5) {
    const postVowelMap = phonemeToGraphemeMap.slice(vowelIndex + 1);
    const postVowelGroups = getGroupedPhonemeGraphemeIndices(
      postVowelMap,
      graphemeTiles,
      tileTypes
    );

    if (animation === 1) {
      const preVowelIndices = phonemeToGraphemeMap.slice(0, vowelIndex + 1);
      phase.activeTiles = preVowelIndices;
      phase.arrowSpan = [preVowelIndices[0], preVowelIndices.at(-1)];
    } else {
      const groupIndex = animation - 2;

      if (groupIndex >= 0 && groupIndex < postVowelGroups.length) {
        const active = postVowelGroups[groupIndex];
        phase.activeTiles = active;
        if (active[0] <= active.at(-1)) {
          phase.arrowSpan = [active[0], active.at(-1)];
        } else {
          phase.arrowSpan = [active[0], active.at(1)];
        }

        if (active.length === 1 && !tileTypes[active[0]]?.includes("silent")) {
          const ipaIdx = phonemeToGraphemeMap.findIndex(
            (idx) => idx === active[0]
          );
          const ipa = ipaPhonemes[ipaIdx];
          if (stopSet.has(ipa) || affricateSet.has(ipa)) {
            phase.shouldAutoAdvance = true;
          }
        }
      } else if (animation === postVowelGroups.length + 2) {
        Object.assign(phase, buildFullPhase());
      } else if (animation === postVowelGroups.length + 3) {
        phase.showImage = true;
      }
    }
  }

  if (level === 6) {
    if (animation === 1) {
      const allIndices = Array.from(
        { length: graphemeTiles.length },
        (_, i) => i
      );
      phase.activeTiles = allIndices;
      phase.arrowSpan = [0, allIndices.length - 1];
      phase.growTiles = true;
    } else if (animation === 2) {
      phase.showImage = true;
    }
  }

  if (level >= 7) {
    const { grouping = [], syllableType = [] } = phonemeData;
    const totalSteps = grouping.length;

    if (animation >= 1 && animation <= totalSteps) {
      let start = 0;
      for (let i = 0; i < animation - 1; i++) {
        const current = grouping[i];
        const next = grouping[i + 1];
        const overlaps = next && current.at(-1) === next[0];

        start += overlaps ? current.length - 1 : current.length;
      }

      console.log(start);
      const currentGroup = grouping[animation - 1];
      const currentIndices = Array.from(
        { length: currentGroup.length },
        (_, j) => start + j
      );

      const first = currentIndices[0];
      const last = currentIndices.at(-1);
      const prev = grouping[animation - 2];
      const next = grouping[animation];

      const sharedAtStart = animation > 1 && prev.at(-1) === currentGroup[0];
      const sharedAtEnd =
        animation < totalSteps && currentGroup.at(-1) === next?.[0];

      // Include shared tile at start
      const activeTiles = [...currentIndices];
      if (sharedAtStart && !activeTiles.includes(first)) {
        activeTiles.unshift(first);
      }

      phase.activeTiles = activeTiles;

      // Arrow spans with fractional logic
      const arrowStart = sharedAtStart ? first + 0.5 : first;
      const arrowEnd = sharedAtEnd ? last + 0.5 : last;
      phase.arrowSpan = [arrowStart, arrowEnd];
    } else if (animation === totalSteps + 1) {
      Object.assign(phase, buildFullPhase());
    } else if (animation === totalSteps + 2) {
      phase.showImage = true;
    } else if (animation === totalSteps + 3) {
      phase.customInstruction = syllableType?.join(" | ");
    }
  }

  const lastIdx = phase.activeTiles.at(-1);
  if (typeof lastIdx === "number") {
    const currentChar = graphemeTiles[lastIdx]?.toLowerCase();
    const firstAhead = graphemeTiles[lastIdx + 1];
    const secondAhead = graphemeTiles[lastIdx + 2];
    const ipaIdx = phonemeToGraphemeMap.indexOf(lastIdx);
    const ipa = ipaPhonemes[ipaIdx];

    let isMagicE = false;
    if (
      /^[aeiou]$/.test(currentChar) &&
      (secondAhead === "(e)" || secondAhead === "e") &&
      longVowelMap[currentChar] === ipa
    ) {
      if (syllableType?.length > 0) {
        const syllableIndex = grouping.findIndex((group) =>
          group.includes(lastIdx)
        );
        const currentSyllableType = syllableType[syllableIndex];
        isMagicE = currentSyllableType === "v_e";
      } else {
        isMagicE = true;
      }
    }

    const isSoftC =
      currentChar === "c" &&
      (firstAhead === "(e)" || firstAhead === "e") &&
      ipa === "s";

    const isSoftG =
      currentChar === "g" &&
      (firstAhead === "(e)" || firstAhead === "e") &&
      ipa === "j";

    if (isMagicE) phase.activeTiles.push(lastIdx + 2);
    else if (isSoftC || isSoftG) phase.activeTiles.push(lastIdx + 1);
  }

  return phase;
};

export const getMaxAnimationSteps = async (phonemeData, level) => {
  if (!phonemeData) return 0;
  const { ipaPhonemes, graphemeTiles } = phonemeData;
  if (level === 1) return ipaPhonemes.length + 2;
  if (level === 2) return ipaPhonemes.length + 3;
  if (level === 3) {
    const map = [];
    const types = [];
    let gIndex = 0;
    for (let i = 0; i < ipaPhonemes.length; i++) {
      while (graphemeTiles[gIndex]?.includes("(")) types[gIndex++] = "silent";
      map[i] = gIndex++;
    }

    const vowelIndex = getVowelIndex(ipaPhonemes);
    const vowelGraphemeIndex = map[vowelIndex];

    const groups = getGroupedPhonemeGraphemeIndices(
      map,
      graphemeTiles,
      types,
      vowelGraphemeIndex
    );

    return groups.length + 3;
  }
  if (level === 4) {
    const vowelIndex = getVowelIndex(ipaPhonemes);
    return ipaPhonemes.length - vowelIndex + 2;
  }
  if (level === 5) {
    const { ipaPhonemes, graphemeTiles } = phonemeData;

    const vowelIndex = getVowelIndex(ipaPhonemes);
    const phonemeToGraphemeMap = [];
    const tileTypes = [];
    let gIndex = 0;

    for (let i = 0; i < ipaPhonemes.length; i++) {
      while (graphemeTiles[gIndex]?.includes("(")) {
        tileTypes[gIndex++] = "silent";
      }
      phonemeToGraphemeMap[i] = gIndex++;
    }

    const postVowelMap = phonemeToGraphemeMap.slice(vowelIndex + 1);
    const postVowelGroups = getGroupedPhonemeGraphemeIndices(
      postVowelMap,
      graphemeTiles,
      tileTypes
    );

    const hasPreVowel = vowelIndex > 0;
    const preVowelSteps = hasPreVowel ? 1 : 0;
    return preVowelSteps + postVowelGroups.length + 2;
  }
  if (level === 6) {
    return 2;
  }
  if (level >= 7) {
    const { grouping = [] } = phonemeData;
    return grouping.length + 2;
  }

  return ipaPhonemes.length + 3;
};

export const getGroupedPhonemeGraphemeIndices = (
  map,
  tiles,
  types,
  stopIndex = Infinity
) => {
  const groups = [];
  for (let i = 0; i < map.length; ) {
    const cur = map[i] ?? -1;
    const n1 = map[i + 1] ?? -1;
    const n2 = map[i + 2] ?? -1;
    const g0 = tiles[cur] ?? "";
    const g1 = tiles[n1] ?? "";
    const g2 = tiles[n2] ?? "";
    const three = (g0 + g1 + g2).toLowerCase();
    const two = (g0 + g1).toLowerCase();

    if (cur >= stopIndex) {
      groups.push([cur]);
      i++;
      continue;
    }

    if (types[cur] === "le-consonant" && types[n1]?.includes("le-special")) {
      groups.push([cur, n1, n2]);
      i += 3;
    } else if (
      specialCombinations.includes(two) &&
      tiles[i + 2]?.toLowerCase() !== "(e)"
    ) {
      groups.push([cur, n1]);
      i += 2;
    } else if (consonantBlends.includes(three)) {
      groups.push([cur, n1, n2]);
      i += 3;
    } else if (consonantBlends.includes(two)) {
      groups.push([cur, n1]);
      i += 2;
    } else {
      groups.push([cur]);
      i++;
    }
  }
  return groups;
};
