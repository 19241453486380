import React, { useEffect } from "react";

import { getPhotoLayoutClass } from "../../../LessonTypeRenderer.logic";
import VowelValley from "../../../../PhonemeTables/VowelValley";
import ConsonantWall from "../../../../PhonemeTables/ConsonantSounds";

import "./VisualReview.scss";

const VisualReview = ({
  slide,
  name,
  fullscreen,
  animation,
  setAnimationMaxCount,
}) => {
  const { content, slideType } = slide;
  const { photos, background, graphemes, phonemes } = content;

  useEffect(() => {
    setAnimationMaxCount(2);
  }, []);

  return (
    <div
      className={`visual-review-consonant ${
        fullscreen ? "slide-fullscreen" : "not-fullscreen"
      }`}
      key={`visual-review-consonant-${name}`}
    >
      {/* Bounty Bar */}
      <div
        className={`block-wall ${animation === 2 ? "block-wall-down" : ""}`}
      />
      <div className="bounty-bar">
        <h1>Bounty: {graphemes.length}</h1>
      </div>

      {/* Grapheme Tiles on Left Side */}
      <div
        className={`grapheme-container ${fullscreen ? "gc-fullscreen" : ""}`}
      >
        {graphemes.map((grapheme, index) => (
          <p
            key={`grapheme-${index}`}
            className={`grapheme-tile ${fullscreen ? "gt-fullscreen" : ""}`}
          >
            {grapheme}
          </p>
        ))}
      </div>

      {/* Backgrounds Layered */}
      <div className={`background-container`}>
        {background.map((bg, index) => {
          if (bg === "vowel-wall") {
            return (
              <VowelValley
                key={`background-${index}`}
                highlights={phonemes}
                half={background.length === 2}
                slideType={slideType}
                fullscreen={fullscreen}
              />
            );
          } else {
            return (
              <ConsonantWall
                key={`background-${index}`}
                highlights={phonemes}
                half={background.length === 2}
                slideType={slideType}
                right={"0%"}
                fullscreen={fullscreen}
              />
            );
          }
        })}
      </div>

      {/* Photos on bottom side */}
      <div
        className={`photo-container ${getPhotoLayoutClass(photos.length)} ${
          animation >= 1 ? "photo-container-show" : ""
        } ${animation === 2 ? "photo-container-down" : ""}`}
      >
        {photos.map((photo, index) => {
          // console.log(photos);
          return (
            <img
              key={`photo-${index}`}
              src={`https://photos.earlyreader.ai/${photo}.webp`}
              alt={`photo-${index}`}
              className={`photo-tile photo-${index + 1}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VisualReview;
