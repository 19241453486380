import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSON5 from "json5";
import {
  faArrowLeft,
  faArrowRight,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";

import LessonSlideRenderer from "../../Comp/LessonTypeRenderer/LessonTypeRenderer";
import {
  processLessonSpecials,
  handleNextSlide,
  handlePrevSlide,
} from "./Lesson.logic";
import { lessonDataTemp } from "./LessonTestData";

import "./Lesson.scss";

const Lesson = ({ toggleFullscreen, fullscreen, setIsLessonRoute }) => {
  const [lessonData, setLessonData] = useState([]);
  const [addData, setAddData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animation, setAnimation] = useState(0);
  const [animationMax, setAnimationMaxCount] = useState(0);
  const [toggleButtons, setToggleButtons] = useState(true);
  const [showInputBox, setShowInputBox] = useState(false);
  const [jsonInput, setJsonInput] = useState("");

  useEffect(() => {
    setIsLessonRoute(true);
    const processed = processLessonSpecials(lessonDataTemp);
    setLessonData(processed);
  }, []);

  const handleNext = useCallback(() => {
    handleNextSlide(
      lessonData,
      currentSlide,
      setCurrentSlide,
      animation,
      animationMax,
      setAnimation
    );
  }, [lessonData, currentSlide, animation, animationMax]);

  const handlePrev = useCallback(() => {
    handlePrevSlide(currentSlide, setCurrentSlide, animation, setAnimation);
  }, [currentSlide, animation]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowLeft":
          event.preventDefault();
          handlePrev();
          break;
        case "ArrowDown":
        case "ArrowRight":
          event.preventDefault();
          handleNext();
          break;
        case "h":
          setToggleButtons((prev) => !prev);
          break;
        case "f":
          toggleFullscreen();
          break;
        case "m":
          setShowInputBox(!showInputBox);
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleNext, handlePrev, toggleFullscreen]);

  useEffect(() => {
    if (lessonData.length && addData) {
      const newLesson = [...lessonData];
      const insertIndex = currentSlide + 1;
      newLesson.splice(insertIndex, 0, addData);
      setLessonData(newLesson);
      // setCurrentSlide(insertIndex); Will move to next slide automatically
      setAnimation(0);
      setAddData(null);
    }
  }, [addData]);

  const current = lessonData[currentSlide] || {};

  return (
    <div
      className={`lesson-container ${fullscreen ? "lesson-fullscreen" : ""}`}
    >
      <LessonSlideRenderer
        slide={{ ...current, name: `${currentSlide}-${animation}` }}
        animation={animation}
        fullscreen={fullscreen}
        addData={setAddData}
        setAnimationMaxCount={setAnimationMaxCount}
        setAnimation={setAnimation}
      />

      <div
        className={`lesson-buttons ${
          fullscreen ? "lesson-buttons-fullscreen" : ""
        } ${toggleButtons ? "" : "lb-none"}`}
      >
        <button
          className={`prev-button ${fullscreen && "previous-fullscreen"}`}
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className={`fullscreen-button ${
            fullscreen && "fullscreen-button-fullscreen"
          }`}
          onClick={toggleFullscreen}
        >
          <FontAwesomeIcon icon={faExpand} />
        </button>
        <button
          className={`next-button ${fullscreen && "next-fullscreen"}`}
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      {showInputBox && (
        <div className="lesson-input-overlay">
          <div className="lesson-input-box">
            <textarea
              placeholder="Paste JSON here..."
              value={jsonInput}
              onChange={(e) => setJsonInput(e.target.value)}
            />
            <div className="lesson-input-buttons">
              <button
                onClick={() => {
                  try {
                    let parsed = JSON5.parse(jsonInput);
                    parsed = processLessonSpecials(parsed);
                    setLessonData(parsed);
                    setCurrentSlide(0);
                    setShowInputBox(false);
                    setJsonInput("");
                  } catch (e) {
                    alert(e);
                  }
                }}
              >
                Add Lesson
              </button>
              <button onClick={() => setShowInputBox(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lesson;
