export const data = [
  [
    ".",
    ".",
    ".",
    ".",
    "Vowel Valley",
    "merge",
    "merge",
    "merge",
    "merge",
    "*speakerIcon",
    "All vowels are voiced.",
    "merge",
    "merge",
  ],
  [
    "*longEFace",
    ".",
    ".",
    ".",
    "merge",
    "merge",
    "merge",
    "merge",
    "merge",
    "merge",
    "merge",
    "merge",
    "merge",
  ],
  [
    "/ē/",
    "*shortIFace",
    ".",
    ".",
    ".",
    ".",
    "*schwaFace",
    ".",
    ".",
    ".",
    ".",
    "*longUFace",
    "*yuFace",
  ],
  [
    "*tree",
    "/ĭ/",
    "*longAFace",
    ".",
    ".",
    ".",
    "/ə/",
    ".",
    ".",
    ".",
    "*ooFace",
    "/ū/",
    "/yū/",
  ],
  [
    ".",
    "*itch",
    "/ā/",
    "*shortEFace",
    ".",
    ".",
    "*banana",
    ".",
    ".",
    "*longOFace",
    "/o͝o/",
    "*spoon",
    "*cube",
  ],
  [
    ".",
    ".",
    "*train",
    "/ĕ/",
    "*shortAFace",
    ".",
    ".",
    ".",
    "*awFace",
    "/ō/",
    "*book",
    ".",
    ".",
  ],
  [
    ".",
    ".",
    ".",
    "*echo",
    "/ă/",
    "*longIFace",
    ".",
    "*shortUFace",
    "/aw/",
    "*boat",
    ".",
    ".",
    ".",
  ],
  [
    "*oiFace",
    "*ouFace",
    ".",
    ".",
    "*apple",
    "/ī/",
    "*shortOFace",
    "/ŭ/",
    "*straw",
    ".",
    "*erFace",
    "*arFace",
    "*orFace",
  ],
  [
    "/oi/",
    "/ou/",
    ".",
    ".",
    ".",
    "*five",
    "/ŏ/",
    "*up",
    ".",
    ".",
    "/er/",
    "/ar/",
    "/or/",
  ],
  [
    "*boy",
    "*cow",
    ".",
    ".",
    ".",
    ".",
    "*octopus",
    ".",
    ".",
    ".",
    "*shirt",
    "*star",
    "*corn",
  ],
];
